import { NextApiRequestCookies } from 'next/dist/server/api-utils';

import fetch from 'cross-fetch';
import Cookie from 'js-cookie';

export const NEXT_PUBLIC_API_URL = process.env.NEXT_PUBLIC_API_URL;
export const NEXT_PUBLIC_CMS_API_URL = process.env.NEXT_PUBLIC_CMS_API_URL;
export const USER_ACCESS_TOKEN_KEY = 'htcUserAccessToken';
export const PROFILE_ACCESS_TOKEN_KEY = 'htcProfileAccessToken';

/**
 * get header to make an authorized request
 * if no token is provided, the user's token from cookies will be used
 *
 * @param token - the user's access token
 */
export const getAuthorizationHeader = (token?: string) => ({
  headers: {
    Authorization: `Bearer ${token ?? Cookie.get(USER_ACCESS_TOKEN_KEY)}`,
  },
});

/**
 * get header to make an authorized request
 *
 * @param cookies - the cookies from the request
 */
export const getAuthorizationHeaderFromCookies = (
  cookies?: NextApiRequestCookies
) => {
  const token = cookies?.[USER_ACCESS_TOKEN_KEY] || undefined;
  return getAuthorizationHeader(token);
};

/**
 * an error thrown by fetchOrThrow, if the request returns an error
 */
export class FetchError extends Error {
  resource: string;
  status: number;
  error: string;
  errorMessage?: string;

  constructor(
    resource: string,
    status: number,
    error: string,
    errorMessage?: string
  ) {
    super();

    this.name = 'FetchError';
    this.message = `Failed to fetch (${status}) ${error} ${errorMessage}`;
    this.resource = resource;
    this.status = status;
    this.error = error;
    this.errorMessage = errorMessage;
  }
}

/**
 * check whether the error is a FetchError
 */
export const isFetchError = (error: unknown): error is FetchError => {
  return error instanceof FetchError;
};

/**
 * try to fetch data from a url, and throw an error if the request returns an error
 *
 * @throws FetchError if the request returns an error
 * @param input - the url to fetch
 * @param init - the fetch options
 * @returns the response data
 */
export const fetchOrThrow = async <T>(
  input: RequestInfo,
  init?: RequestInit
): Promise<T> => {
  const response = await fetch(input, init);
  const status = response.status;
  const result = await response.json();

  if (!response.ok || ('success' in result && !result.success)) {
    throw new FetchError(
      input.toString(),
      status,
      result.error || result.errors || 'Unknown error',
      result.errorMessage || result.message || 'Something went wrong'
    );
  } else {
    return result as T;
  }
};

/**
 * create a signed url and upload a file to it
 *
 * @param file - the file to upload
 * @returns the object id of the uploaded file
 */
export const uploadObjectToS3 = async (file: File): Promise<string> => {
  const response = await fetchOrThrow<{ url: string; objectId: string }>(
    `${NEXT_PUBLIC_API_URL}/users/create_signed_url`,
    {
      method: 'POST',
      ...getAuthorizationHeader(),
    }
  );
  if (!response.url || !response.objectId) {
    throw new Error(`Failed to create signed url.`);
  }
  const { objectId, url } = response;
  const uploadResponse = await fetch(url, {
    method: 'PUT',
    headers: { 'Content-Type': file.type ?? 'image/png' },
    body: file,
  });

  if (!uploadResponse.ok) {
    throw new FetchError(
      url,
      uploadResponse.status,
      uploadResponse.statusText,
      `Failed to upload object.`
    );
  }
  return objectId;
};
